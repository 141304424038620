@import '../../style/App.scss';

.hero {
  padding-top: 140px;
  width: 100%;
  padding-bottom: 60px;

  .hero-info {
    padding-top: 30px;

    p {
      padding-top: 20px;
    }
  }

  .hero-image {
    max-width: 670px;
    height: auto;
    float: right;
    img {
      width: 100%;
      object-fit: fill;
    }
  }
}

@media screen and (max-width: 1080px) {
  .hero {
    padding-top: 140px;

    .hero-info {
      padding-top: 0px;
    }

    .hero-image {
      float: none;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 990px) {
  .hero {
    h1 {
      font-size: 4rem;
      line-height: 5rem;
    }
  }
}
