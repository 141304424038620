@import '../../style/App.scss';

.footer {
  background-color: $accent-color;
  width: 100%;
  min-height: 120px;

  .footer-box {
    min-height: 120px;
    display: flex;
    align-items: center;

    p {
      color: $white-color;
      font-size: 1.1rem;
      margin-left: 30px;
    }
  }

  .back-to-top {
    float: right;
    cursor: pointer;
    img {
      margin-left: 10px;
    }
  }

  .back-to-top:hover {
    p {
      color: $main-color;
    }
  }
}

@media screen and (max-width: 580px) {
  .footer {
    .footer-box {
      padding-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      min-height: 80px;
      img {
        padding-bottom: 30px;
      }
      p {
        padding: 0;
        margin: 0;
        text-align: center;
      }
    }
    .back-to-top {
      display: flex;
      justify-content: center;
      align-items: center;
      float: none;
      flex-direction: row;

      img {
        padding-bottom: 0px;
      }
    }
  }
}
